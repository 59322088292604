microflow
  .controller("UIInfiniteScrollCtrl", [
    "$scope",
    "$http",
    "$attrs",
    function($scope, $http, $attrs) {
      $scope.microflowREST = microflowREST;

      var actionFlowId = $attrs.pgInfiniteAction;
      var flowResultKey = $attrs.pgScrollList;

      var flowDetail = microFlowKey.find(function(obj) {
        return obj.flowName == actionFlowId;
      });

      $scope.processName = flowDetail.flowId;

      $scope.dataSource = {};
      $scope.dataSource.get = function(index, count, success) {
        var dataObject = {
          flowName: $scope.processName,
          object: {
            nextPage: {
              currentPage: index,
              bufferSize: count
            }
          }
        };
        var req = {
          method: "POST",
          url: $scope.microflowREST,
          data: JSON.stringify(dataObject),
          headers: {
            "Content-Type": undefined
          }
        };
        return new Promise(function(resolve, reject) {
          $http(req).then(
            function(res) {
              var objRes = res.data;
              if (objRes.responseStatus === "SUCCESS") {
                success(objRes.responseObjectsMap[flowResultKey]);
                resolve(objRes);
              } else {
                reject(objRes);
              }
            },
            function(res) {
              reject(res);
            }
          );
        });
      };
    }
  ])
  .directive("infiniteScroll", [
    function() {
      return {
        controller: "UIInfiniteScrollCtrl",
        controllerAs: "infiniteCtrl",
        scope: true,
        restrict: "A",
        priority: 1001
      };
    }
  ]);
